import styled from "@emotion/styled";


const SubmitButton = styled.button`

  display: block;
  border-radius: 10px;
  outline: none;
  height: 40px;
  width: 100%;
  font-size: inherit;
  background-color: ${props => (props.disabled ? "#71db77" : "#71db77")};
  border: none;
  color: #000;
  font-weight: 600 !important;
  cursor: pointer;

  
`;



export default SubmitButton;
