import React, { useState } from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import "./index.css";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { navigate } from "gatsby-link";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { Elements } from "@stripe/react-stripe-js";
import { CountUp } from 'countup.js';

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY);

const source10 = "- 4 face shields, 4 sewn masks, and 4 KN95 masks to U.S. Army Soldiers in Texas\n";
const quote10 = "\"I have seen many soldiers using the same medical masks to the point where they are brown so I wanted to make them all safety bundles for when they get home and donate to others in the quarantine tents so they have clean and well put together gear when they work and go about their daily lives. The masks you sent us go perfectly and are in regulations with their uniforms.\"";
const source20 = "- 20 face shields and 9 KN95 masks to Common Pantry (food pantry in Illinois)\n";
const quote20 = "\"PPE4ALL was professional, quick to respond, and efficient. The quality of the masks and shields are above many of those that we've received thus far in the pandemic\"";
const source50 = "- 45 face shields and 20 KN95 masks to HeartLand Hands Food Pantry (in Mississippi)\n";
const quote50 = "\"What a blessing you are! Thank you for all you are doing in these stressful and uncharted times!\"";
const source100 = "- 100 face shields to speech pathologists at Speech Therapy Works (in Texas)\n";
const quote100 = "\"Thank you for all that you do! We are eternally grateful and appreciate your company so much! You are so wonderful! I am so incredibly grateful for any and all help that your company can provide! The face shields are absolutely so appreciated.\"";
const source200 = "- 100 face shields and 140 sewn masks to Lexington Vocational Services for the Deaf (in New York)\n";
const quote200 = "\"I am thrilled that we will be getting these PPEs for the Deaf individuals we serve and for the providers who work with them. This will make a tremendous difference as we proceed with our mission. We can’t thank you enough.\"";
var sourceMsg = "";
var quoteMsg = "";

const PricingPageTemplate = ({
  title,
  meta_title,
  meta_description,
  pricing,
  donationTotal,
}) => {
  const [price, setPrice] = useState(0);
  const [invalidPrice, setInvalidPrice] = useState(true);
  const [customPrice, setCustomPrice] = useState(0);
  const [showLabel, setShowLabel] = useState(false);
  const [labelText, setLabel] = useState("Custom");
  const [showForm, setShowForm] = useState(false);

  
  function isNormalInteger(str) {
    const n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  }

  function handleChange(event) {
    if (
      event.target.value < 5 ||
      event.target.value == null ||
      !isNormalInteger(event.target.value)
    ) {
      setInvalidPrice(true);
      setShowForm(false);
    } else {
      setInvalidPrice(false);
      setPrice(event.target.value);
      setCustomPrice(event.target.value);
      setShowForm(true);
    }
  }

  function handleClickButton(event) {

    var numFaceShields = Math.floor(event.target.value / 0.85)
    var numSurgicalMasks = Math.floor(event.target.value / 0.54)
    var numHandSewnMasks = Math.floor(event.target.value / 5.47)
    var numN95Masks = Math.floor(event.target.value / 2.6)
    
    toggleColumn(event);
    setPrice(event.target.value);
    setShowLabel(false);
    setLabel("Custom");
    setInvalidPrice(false);
    setShowForm(true);

    switch(event.target.value) {
      case "10":
        sourceMsg = source10;
        quoteMsg = quote10;
        break;
      case "20":
        sourceMsg = source20;
        quoteMsg = quote20;
        break;
      case "50":
        sourceMsg = source50;
        quoteMsg = quote50;
        break;
      case "100":
        sourceMsg = source100;
        quoteMsg = quote100;
        break;
      case "200":
        sourceMsg = source200;
        quoteMsg = quote200;
        break;
      default:
        sourceMsg = "Error";
        quoteMsg = "There was an error";
        break;
    }

    var counter = new CountUp('target',numFaceShields);
    var counter2 = new CountUp('target2',numSurgicalMasks);
    var counter3 = new CountUp('target3',numHandSewnMasks);
    var counter4 = new CountUp('target4',numN95Masks);
    
    counter.start();
    counter2.start();
    counter3.start();
    counter4.start();
  }

  function handleCustomClick(event) {

    toggleColumn(event);
    setShowLabel(true);
    setLabel("");
    setInvalidPrice(true);

    var numFaceShields = Math.floor(event.target.value / 1.27);
    var numSurgicalMasks = Math.floor(event.target.value / 0.14);
    var numHandSewnMasks = Math.floor(event.target.value / 5);
    var numN95Masks = Math.floor(event.target.value / 3.4);

    var counter = new CountUp('target',numFaceShields);
    var counter2 = new CountUp('target2',numSurgicalMasks);
    var counter3 = new CountUp('target3',numHandSewnMasks);
    var counter4 = new CountUp('target4',numN95Masks);
    
    counter.start();
    counter2.start();
    counter3.start();
    counter4.start();
  }

  function toggleColumn(event) {
    const x = document.getElementById("new");
    const y = document.getElementById("old");
    
    if (!x.classList.contains('transitioning')) {
      y.style.opacity = 0;
      x.classList.remove("donateTransparent");
      x.classList.add("transitioning");
      setTimeout(fadeInQuote, 500);
    } else {
      fadeInQuote()
    }
  }

  function fadeInQuote() {
    const q = document.getElementById("quote");
    const s = document.getElementById("source");
    if (q.classList.contains('transitioning')) {
      resetQuote();
    }
    if (!q.classList.contains('transitioning')) {
      q.classList.remove("donateTransparent");
      s.classList.remove("donateTransparent");
      q.classList.add("transitioning");
      s.classList.add("transitioning");
    }
  }

  function resetQuote() {
    const q = document.getElementById("quote");
    const s = document.getElementById("source");
    q.classList.add("donateTransparent");
    s.classList.add("donateTransparent");
    q.classList.remove("transitioning");
    s.classList.remove("transitioning");
  }

  return (
    <div>
      <Helmet>
        <title>{meta_title}</title>
        <meta name="description" content={meta_description} />
        <script src="build/react.js" />
        {/* <script
          src="https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit"
          async
          defer
        /> */}
      </Helmet>
      <section id="toppart" className="hero is-primary is-bold is-medium">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="section">
                  <h1 className="title">{title}</h1>
                  {/* <Progress
                    percent={((donationTotal / 25000) * 100).toFixed(2)}
                    status="active"
                    theme={{
                      active: {
                        color: "#71db77",
                        trailColor: "green",
                      },
                    }}
                  /> */}
                  <p>
                    <br />
                    {/* We've received <strong>${donationTotal}</strong> of our
                    $25,000 campaign.  */}
                    <strong>Your donations save lives.</strong> View{" "}
                    <a href="https://www.gofundme.com/f/uwnhq-ppe4nyc">
                      {" "}
                      our GoFundMe here
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="quoteAndSource">
          <div class="quote donateTransparent" id="quote">
          <p>{quoteMsg}</p>
        </div>
        <div class="quoteSource donateTransparent" id="source">
          <p>{sourceMsg}</p>
        </div>
        </div>
        </div>
      </section>
      <section className="section3 aboutus">
        <div className="columns">
          <div className="column is-half donateTransparent" id="new">
            <div className="column is-10 is-offset-1 is-sized">
              <h3 className="styledSubHeader">
                Thank you for donating. Your contribution can help us produce:
              </h3>
              <br></br>
              <div class="rowTest">
                <div class="columnTest">
                  <h3>Hand Sewn Mask</h3>
                  <img src={require("../../assets/img/hand_sewn_mask.png")} width="200px" height="100px" loading="lazy" />
                  <div className='count' id='target3'>0</div>
                </div>
                <div class="columnTest">
                  <h3>Surgical Mask</h3>
                  <img src={require("../../assets/img/surgical_mask.png")} width="200px" height="100px" loading="lazy" />
                  <div className='count' id='target2'>0</div>
                </div>
              </div>
              <div class="rowTest">
                <div class="columnTest">
                  <h3>N95 Mask</h3>
                  <img src={require("../../assets/img/n95_mask.png")} width="200px" height="50px" loading="lazy" />
                  <div className='count' id='target4'>0</div>
                </div>
                <div class="columnTest">
                  <h3>Face Shield</h3>
                  <img src={require("../../assets/img/face_shield.png")} width="200px" height="100px" loading="lazy" />
                  <div className='count' id='target'>10</div>
                </div>
              </div>
                
            </div>
          </div>
          <div className="column is-half donate transitioning" id="old">
            <div className="column is-10 is-offset-1 is-sized">
              <div className="bodyHeaderGroup">
                <h5 className="styledHeader">Monetary donations.</h5>
              </div>
              <div className="mapTextGroup">
                <h5 className="bodyText">
                  All of your donations go towards sourcing materials to create
                  PPE, purchasing PPE, and facilitating delivery of PPE to the
                  greater New York area. We pledge to utilize your donations
                  efficiently and effectively as we deliver aid to those in dire
                  need. Thank you for your part in helping us reach our goal!
                  <br></br>
                  <br></br>
                  You will receive an email with the receipt of your donation
                  immediately after payment is processed.
                </h5>
              </div>
              <img src={require("../../assets/img/photo1.jpg")} />
            </div>
          </div>
          <div className="column is-half donateForm">
            <div className="containerForDonate">
              <div className="content has-text-centered">
                <img
                  className="padtop padbottom"
                  src={require("../../assets/img/logo.svg")}
                  width="90"
                />
                <p>Select a donation amount below.</p>
                <div className="radio-toolbar">
                  <input
                    type="radio"
                    className="amount"
                    name="amount"
                    value={10}
                    id="amount-0"
                    onClick={handleClickButton}
                  />
                  <label htmlFor="amount-0" className="amount">
                    $10
                  </label>
                  <input
                    type="radio"
                    className="amount"
                    name="amount"
                    value={20}
                    id="amount-1"
                    onClick={handleClickButton}
                  />
                  <label htmlFor="amount-1" className="amount">
                    $20
                  </label>
                  <input
                    type="radio"
                    className="amount"
                    name="amount"
                    value={50}
                    id="amount-2"
                    onClick={handleClickButton}
                  />
                  <label htmlFor="amount-2" className="amount">
                    $50
                  </label>
                  <input
                    type="radio"
                    className="amount"
                    name="amount"
                    value={100}
                    id="amount-3"
                    onClick={handleClickButton}
                  />
                  <label htmlFor="amount-3" className="amount">
                    $100
                  </label>
                  <input
                    type="radio"
                    className="amount"
                    name="amount"
                    value={200}
                    id="amount-4"
                    onClick={handleClickButton}
                  />
                  <label htmlFor="amount-4" className="amount">
                    $200
                  </label>
                  <input
                    type="radio"
                    className="amount"
                    name="amount"
                    value=""
                    id="amount-5"
                    onClick={handleCustomClick}
                  />
                  <label htmlFor="amount-5" className="amount">
                    {labelText}
                    {showLabel && (
                      <input
                        id="customDon"
                        type="number"
                        pattern="0+\.[0-9]*[1-9][0-9]*$"
                        placeholder="0"
                        onChange={handleChange}
                      />
                    )}
                  </label>
                  <div id="error-message" />
                </div>
                <div className={showForm ? "" : "form-invisible"}>
                  <Elements stripe={stripePromise}>
                    <div className={showForm ? "" : "form-invisible"}>
                      <CheckoutForm
                        disableCheckout={invalidPrice}
                        price={price}
                        onSuccessfulCheckout={() => navigate("/donate/success")}
                      />
                    </div>
                  </Elements>
                <p id="stripeinfo" className={showForm ? "" : "form-invisible-stripeinfo"}>
                  Payments are processed by Stripe. <br /> Stripe uses secure
                  encryption to keep your details safe: none of your payment
                  information is stored on PPE4NYC servers.
                </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

PricingPageTemplate.propTypes = {
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  pricing: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
  }),
  donationTotal: PropTypes.number,
}

export default PricingPageTemplate
