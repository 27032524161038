import React, { useState, useRef } from 'react'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import styled from '@emotion/styled'
import axios from 'axios'

import Row from './prebuilt/Row'
import BillingDetailsFields from './prebuilt/BillingDetailsFields'
import SubmitButton from './prebuilt/SubmitButton'
import CheckoutError from './prebuilt/CheckoutError'
import './CheckoutForm.css'
import Reaptcha from 'reaptcha'

const CardElementContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  & .StripeElement {
    width: 100%;
    padding: 15px;
  }
`

const CheckoutForm = ({ disableCheckout, price, subscription, onSuccessfulCheckout }) => {
  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [captchaRef, setCaptchaRef] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const onVerify = reCaptchaResponse => {
    console.log("verifying");
    setCaptchaToken(true);
  }

  const handleCardDetailsChange = ev => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };

  const handleFormSubmit = async (ev) => {
    // verify captcha
    ev.preventDefault();

    const billingDetails = {
      name: ev.target.name.value,
      email: ev.target.email.value,
      address: {
        city: ev.target.city.value,
        line1: ev.target.address.value,
        state: ev.target.state.value,
        postal_code: ev.target.zip.value
      }
    };

    setProcessingTo(true);

    const cardElement = elements.getElement("card");

    try {
      if (captchaToken) {
        // Create payment method and check that it was successful
        const paymentMethodReq = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: billingDetails
        });

        if (paymentMethodReq.error) {
          // setCaptchaFailed(true);
          setCheckoutError(paymentMethodReq.error.message);
          setProcessingTo(false);
          setCaptchaToken(false);
          return;
        }

        if (!subscription) {
          // Call stripe-charge function w/ appropriate parameters for one-time payment
          const { data: clientSecret } = await axios.post("https://ppe4all.net/.netlify/functions/stripe-charge", {
            amount: price * 100,
            email: billingDetails.email,
            subscription: false,
          });

          // Confirm card payment
          const { error } = await stripe.confirmCardPayment(clientSecret.client_secret, {
            payment_method: paymentMethodReq.paymentMethod.id,
            receipt_email: billingDetails.email,
          });

          if (error) {
            // setCaptchaFailed(true);
            setCheckoutError(error.message);
            setProcessingTo(false);
            console.log("captcha ref (104)", captchaRef);
            captchaRef.reset();
            return;
          }

        } else {

          // Call stripe-charge function w/ appropriate parameters for recurring payment
          const { data: clientSecret } = await axios.post("https://ppe4all.net/.netlify/functions/stripe-charge", {
            amount: price * 100,
            email: billingDetails.email,
            paymentMethod: paymentMethodReq.paymentMethod.id,
            subscription: true,
          });

          const { error } = await stripe.confirmCardPayment(clientSecret.client_secret, {
            payment_method: paymentMethodReq.paymentMethod.id,
            receipt_email: billingDetails.email,
          })

          if (error) {
            setCaptchaToken(false);
            setCheckoutError(error.message + "\n Please refresh to try again.");
            setProcessingTo(false);
            captchaRef.reset();
            return;
          }
        }

        onSuccessfulCheckout();
      } else {
        throw console.error("Captcha not fulfilled.");
      }

    } catch (err) {
        console.log(err.message);
        setCaptchaToken(false);
        console.log("captcha ref (142)", captchaRef);
        captchaRef.reset();
        setCheckoutError("An error occured while processing your payment. Your card has not been charged.");
       setProcessingTo(false);
       setSubmitted(true);
       return;
    }
  };

  const iframeStyles = {
    base: {
      color: "#71db77",
      fontSize: "16px",
      iconColor: "#fff",
      "::placeholder": {
        color: "#56a361"
      }
    },
    invalid: {
      iconColor: "#FFC7EE",
      color: "#FFC7EE"
    },
    complete: {
      iconColor: "#cbf4c9"
    }
  };

  const cardElementOpts = {
    iconStyle: "solid",
    style: iframeStyles,
    hidePostalCode: true
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <Row id="Row">
        <BillingDetailsFields />
      </Row>
      <Row id="Row">
        <CardElementContainer>
          <CardElement
            options={cardElementOpts}
            onChange={handleCardDetailsChange}
          />
        </CardElementContainer>
      </Row>
      {checkoutError && <CheckoutError>{checkoutError}</CheckoutError>}
      {/* for testing, use 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI recaptcha key */}
      {typeof window !== 'undefined' && window.location.pathname.indexOf('admin') > 0 ? <div></div> :
        <div className='recaptcha'>
          <Reaptcha sitekey={process.env.GATSBY_RECAPTCHA_KEY} ref={e => (setCaptchaRef(e))} onVerify={onVerify} />
        </div>
      }
      <Row id="Row">
        <SubmitButton id='SubmitButton' disabled={isProcessing || disableCheckout || !captchaToken }>
          {!captchaToken ? (!submitted ? 'Verify your authenticity above' : 'Please refresh the page to try again.') : ""}
          {(disableCheckout && captchaToken) ? 'Invalid price' : ""}
          {isProcessing ? 'Processing...' : ""}
          {!disableCheckout && !isProcessing && captchaToken ? `Pay $${price}`:""}
        </SubmitButton>
      </Row>
    </form>
  );
};

export default CheckoutForm;