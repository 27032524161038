import styled from "@emotion/styled";

const Row = styled.div`
  width: 475px;
  margin: 30px auto;
  border-radius: 10px;
  background-color: #424242;
  position: relative;
`;

export default Row;
